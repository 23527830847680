// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VCursor_1TeAt{display:block;pointer-events:none}.VCursor_1TeAt._hide_PkZGZ .wrapper_tfsVE{opacity:0;transition:opacity .3s ease}.VCursor_1TeAt._absolute_OBhit .wrapper_tfsVE{position:absolute}@media(max-width:1279.98px){.VCursor_1TeAt{display:none}}.wrapper_tfsVE{align-items:center;-webkit-backface-visibility:hidden;backface-visibility:hidden;display:flex;justify-content:center;left:0;position:fixed;top:0;z-index:900}.content_1lrjh{position:absolute}.content_1lrjh *{cursor:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"VCursor": "VCursor_1TeAt",
	"_hide": "_hide_PkZGZ",
	"wrapper": "wrapper_tfsVE",
	"_absolute": "_absolute_OBhit",
	"content": "content_1lrjh"
};
module.exports = ___CSS_LOADER_EXPORT___;
