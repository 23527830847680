// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OfferCard_l2pqm{background-color:#161119;justify-content:space-between}.OfferCard_l2pqm,.top_QpPT6{display:flex;flex-direction:column}.top_QpPT6{margin-bottom:auto;padding:2.4rem}@media(max-width:1279.98px){.top_QpPT6{padding:2rem}}.title_OLFya{font-family:\"Bebas-Neue\",sans-serif;font-size:4.2rem;font-size:42px;font-weight:700;letter-spacing:-.084rem;line-height:1;margin-bottom:2.4rem}@media(max-width:1279.98px){.title_OLFya{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1;margin-bottom:2rem}}.option_-0JZh,.options_PO-mn{display:flex;flex-direction:column}.option_-0JZh{padding-left:1.4rem;position:relative}.option_-0JZh:before{background-color:#804dff;content:\"\";height:.6rem;left:0;position:absolute;top:.6rem;width:.6rem}.option_-0JZh+.option_-0JZh{margin-top:1.2rem}.optionTitle_h3VvS{font-size:1.5rem;font-size:15px;font-weight:400;line-height:2.1rem}.optionDescription_\\+\\+YQ\\+{color:#848286;font-size:12px;font-weight:400;line-height:14px;margin-top:.8rem}.price_UZqz7{font-family:\"Bebas-Neue\",sans-serif;font-size:4.2rem;font-weight:700;letter-spacing:-.084rem;line-height:1;padding:0 2.4rem}@media(max-width:1279.98px){.price_UZqz7{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1;padding:0 2rem}}.btn_8pMW5{margin-top:2rem;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"OfferCard": "OfferCard_l2pqm",
	"top": "top_QpPT6",
	"title": "title_OLFya",
	"option": "option_-0JZh",
	"options": "options_PO-mn",
	"optionTitle": "optionTitle_h3VvS",
	"optionDescription": "optionDescription_++YQ+",
	"price": "price_UZqz7",
	"btn": "btn_8pMW5"
};
module.exports = ___CSS_LOADER_EXPORT___;
