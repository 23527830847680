
// utils
import { formatDateTime } from '@@/common/assets/js/utils/date-time-utils';
// components
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';
import VButton from '~/components/ui/button/VButton.vue';

export default {
    name: 'EventCard',

    components: {
        VButton,
        VImageSrcSet,
    },

    props: {
        eventData: {
            type: Object,
            default: () => ({}),
        },

        newTab: {
            type: Boolean,
            default: true,
        },

        isGray: {
            type: Boolean,
            default: false,
        },

        unclickable: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                [this.$style._gray]: this.isGray,
                [this.$style._unclickable]: this.unclickable,
                [this.$style._withoutButton]: !this.eventData.registration_required,
            };
        },

        attrs() {
            if (this.newTab) {
                return {
                    target: '_blank',
                    href: `/events/${this.eventData.id}/`,
                };
            }

            return {
                to: `/events/${this.eventData.id}/`,
            };
        },

        eventDate() {
            const startDate = formatDateTime(this.eventData.start_date, this.eventData.exact_date_unknown ? '$F $y' : '$j $E $y');

            const endDate = this.eventData.end_date ? formatDateTime(this.eventData.end_date, this.eventData.exact_date_unknown ? '$F $y' : '$j $E $y') : '';

            let res = startDate;

            if (endDate && endDate !== startDate) {
                res += ` &mdash; ${endDate}`;
            }

            return res;
        },

        buttonTag() {
            if (!this.unclickable) {
                return 'div';
            }

            return this.newTab ? 'a' : 'nuxt-link';
        },
    },

    methods: {
        onButtonEnter() {
            if (this.$deviceIs.pc && this.unclickable) {
                this.$emit('button-enter');
            }
        },

        onButtonLeave() {
            if (this.$deviceIs.pc && this.unclickable) {
                this.$emit('button-leave');
            }
        },
    },
};
