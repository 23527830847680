
// utils
import { freeze } from '@@/common/assets/js/utils/common-utils';

export default {
    name: 'StaggeredTitle',

    props: {
        lines: {
            type: Array,
            default: () => [],
        },

        // максимальное перекрытие, в процентах от высоты линии!
        maxOverflow: {
            type: Number,
            default: 100,
        },

        cutLastButton: {
            type: Boolean,
            default: false,
        },

        // позиция начала отрабатывания перекрытия, относительно viewport, по умолчанию начинает отрабатывать сразу (bottom - нижний край viewport)
        startPosition: {
            type: String,
            default: 'bottom',
        },

        size: {
            type: String,
            validator: value => ['xl', 'large', 'medium', 'small', 'custom'].includes(value),
            default: 'large',
        },

        refreshOnIntersect: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            structure: '',
            progress: 0,
            height: 0,
            rowHeight: 0,
            scrollTrigger: null,

            intersectParams: {
                handler: e => this.handleIntersect(e),
                options: {
                    rootMargin: '100px 0px 100px',
                },
            },
        };
    },

    computed: {
        padding() {
            return this.progress * this.maxOverflow * this.rowHeight / 100;
        },

        blockHeight() {
            if (!this.cutLastButton) {
                return this.height;
            }

            return this.height - this.padding;
        },

        classList() {
            return [{
                [this.$style[`_${this.size}`]]: this.size,
            }];
        },
    },

    watch: {
        progress() {
            this.height = this.$refs.wrapper.scrollHeight;
        },
    },

    async mounted() {
        this.createStructure();

        await this.$nextTick();

        this.height = this.$refs.wrapper.scrollHeight;
        this.rowHeight = this.$refs.wrapper?.children?.[0]?.offsetHeight || 0;

        await freeze(150);

        requestAnimationFrame(this.initAnimation);
    },

    beforeDestroy() {
        this.scrollTrigger?.kill();
    },

    methods: {
        createStructure() {
            const el = document.createElement('div');

            if (this.lines?.[0]?.subtitle) {
                el.innerHTML = `<span>${this.lines?.[0]?.label || ''}</span><span>${this.lines?.[0]?.subtitle}</span>`;
            } else {
                el.innerHTML = `<span>${this.lines?.[0]?.label || ''}</span>`;
            }

            el.style.backgroundColor = this.lines?.[0]?.color || '$primary-900';

            let curEl = el;
            for (let i = 1; i < this.lines.length; ++i) {
                const div = curEl.appendChild(document.createElement('div'));

                if (this.lines?.[i]?.subtitle) {
                    div.innerHTML = `<span>${this.lines?.[i]?.label}</span><span>${this.lines?.[i]?.subtitle}</span>`;
                } else {
                    div.innerHTML = `<span>${this.lines?.[i]?.label}</span>`;
                }

                div.style.backgroundColor = this.lines?.[i]?.color || '$primary-900';

                curEl = div;
            }
            this.structure = el.outerHTML;
        },

        handleIntersect(e) {
            if (e && !e?.[0]?.isIntersecting || !this.refreshOnIntersect) {
                return;
            }

            this.scrollTrigger?.scrollTrigger.refresh();
        },

        initAnimation() {
            this.scrollTrigger = this.$gsap.to(this.$el, {
                scrollTrigger: {
                    trigger: this.$el,
                    start: () => `top ${this.startPosition}`,
                    end: 'top top',
                    toggleActions: 'restart complete reverse reset',
                    scrub: 1,
                    onUpdate: self => this.progress = self.progress,
                },
            });
        },
    },
};
