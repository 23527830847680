
export default {
    name: 'VFlashlight',
    props: {
        color: {
            type: String,
            default: 'rgba(128, 77, 255, .3)',
        },

        background: {
            type: String,
            default: 'rgba(0, 0, 0, .5)',
        },

        disable: {
            type: Boolean,
            default: false,
        },

        attach: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            top: 0,
            left: 0,
            parent: null,
            cursorOnParent: false,
        };
    },

    beforeDestroy() {
        this.parent.removeEventListener('mousemove', this.onMouseMove);
        this.parent.removeEventListener('mouseleave', this.onMouseLeave);
    },

    mounted() {
        this.parent = this.attach || this.$el.parentElement;
        this.parent.addEventListener('mousemove', this.onMouseMove);
        this.parent.addEventListener('mouseleave', this.onMouseLeave);
    },

    methods: {
        onMouseMove(e) {
            const coordsParent = this.parent.getBoundingClientRect();
            this.cursorOnParent = true;
            this.top = e.clientY - coordsParent.top;
            this.left = e.clientX - coordsParent.left;
        },

        onMouseLeave() {
            this.cursorOnParent = false;
        },
    },
};
