// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignUpCard_dChh7{display:grid;grid-template-columns:repeat(2,1fr);overflow:hidden;padding:2.4rem;position:relative;grid-gap:1.2rem}@media(max-width:767.98px){.SignUpCard_dChh7{display:flex;flex-direction:column;justify-content:space-between;padding:1.6rem}}.left_ZiJ2C,.right_4I-ii{display:flex;flex-direction:column;justify-content:space-between}.left_ZiJ2C{max-width:40rem}@media(max-width:767.98px){.left_ZiJ2C{flex-direction:row;max-width:unset}}.right_4I-ii{padding:0 2.4rem}@media(max-width:767.98px){.right_4I-ii{padding:0}}.title_LKvjI{font-family:\"Bebas-Neue\",sans-serif;font-size:4.2rem;font-weight:700;letter-spacing:-.084rem;line-height:1}@media(max-width:767.98px){.title_LKvjI{display:none}}.titleMobile_RNYD1{display:none;font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:400;letter-spacing:-.02em}@media(max-width:767.98px){.titleMobile_RNYD1{display:flex}}.description_v6q7W{font-size:1.5rem;font-weight:400;line-height:2.1rem}@media(max-width:767.98px){.description_v6q7W{display:none}}.descriptionMobile_sFIrc{color:#cecdcf;display:none;font-size:1.4rem;font-weight:400}@media(max-width:767.98px){.descriptionMobile_sFIrc{display:flex}}.img_Sm10Y{height:100%;position:absolute;width:100%;z-index:-1}@media(max-width:767.98px){.img_Sm10Y{object-fit:contain}}.btn_7VEZj{margin-bottom:1.2rem;width:21.8rem}@media(max-width:767.98px){.btn_7VEZj{margin:1.6rem 0 0;width:100%}}.logo_624xx{height:7.4rem;object-fit:contain;width:-webkit-max-content;width:max-content}@media(max-width:767.98px){.logo_624xx{height:5.6rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"SignUpCard": "SignUpCard_dChh7",
	"left": "left_ZiJ2C",
	"right": "right_4I-ii",
	"title": "title_LKvjI",
	"titleMobile": "titleMobile_RNYD1",
	"description": "description_v6q7W",
	"descriptionMobile": "descriptionMobile_sFIrc",
	"img": "img_Sm10Y",
	"btn": "btn_7VEZj",
	"logo": "logo_624xx"
};
module.exports = ___CSS_LOADER_EXPORT___;
