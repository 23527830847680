
import VButton from '~/components/ui/button/VButton.vue';

export default {
    name: 'OfferCard',
    components: { VButton },

    props: {
        cardData: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        buttonTitle() {
            if (this.cardData.name === 'Девелопер') {
                return 'Вступить';
            }

            return 'Подать заявку в лист ожидания';
        },
    },

};
