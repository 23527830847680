// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PurposeCard_Eu08s{background-color:#161119;display:flex;justify-content:space-between;overflow:hidden;position:relative}.circle_0wW-P{background:linear-gradient(0deg,#804dff 57.35%,#804dff00 68.8%);border-radius:9999px;bottom:-4.8rem;filter:blur(150px);height:21.8rem;left:0;position:absolute;width:21.8rem;z-index:0}@media(max-width:1279.98px){.circle_0wW-P{display:none}}.left_jjhld{display:flex;flex:1 1 auto;flex-direction:column;justify-content:space-between;padding:2.4rem 0 2.4rem 2.4rem}@media(max-width:1279.98px){.left_jjhld{padding:2rem}}.title_H3-4j{font-family:\"Bebas-Neue\",sans-serif;font-size:4.2rem;font-weight:700;letter-spacing:-.084rem;line-height:1;position:relative;z-index:2}@media(max-width:1279.98px){.title_H3-4j{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1}}.description_ufZb1{color:#848286;font-size:1.5rem;font-weight:400;line-height:2.1rem;margin-top:auto;position:relative;z-index:2}@media(max-width:1279.98px){.description_ufZb1{max-width:41rem}}@media(max-width:767.98px){.description_ufZb1{color:#fff;font-size:1.4rem;line-height:1.8rem}}.image_And\\+E{flex:0 0 auto;height:100%;max-width:23.8rem;position:relative;z-index:1}@media(max-width:1279.98px){.image_And\\+E{height:80%;position:absolute;right:0;top:10%;width:100%}.image_And\\+E img{object-position:right}.image_And\\+E:after{background:linear-gradient(180deg,#16111900 50%,#161119);content:\"\";height:100%;left:0;position:absolute;top:0;width:100%;z-index:2}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"PurposeCard": "PurposeCard_Eu08s",
	"circle": "circle_0wW-P",
	"left": "left_jjhld",
	"title": "title_H3-4j",
	"description": "description_ufZb1",
	"image": "image_And+E"
};
module.exports = ___CSS_LOADER_EXPORT___;
