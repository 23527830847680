
/**
 * Элемент для создания кастомного курсора
 * @version 1.0.1
 * @displayName VСursor
 */

export default {
    name: 'VCursor',

    props: {
        /**
         * Если элемент находится в модальном окне
         */
        inModal: {
            type: Boolean,
            default: true,
        },

        /**
         * Селектор элемента к которому привязан курсор
         */
        attach: {
            type: String,
            default: '',
        },

        /**
         * Отключает исключения
         */
        alwaysVisible: {
            type: Boolean,
            default: false,
        },

        // Класс исключение на котором нужно будет прятать курсор
        classExclusion: {
            type: String,
            default: 'cursor-exclusion',
        },

        absolute: Boolean,
    },

    data() {
        return {
            hideCursor: true,
            interval: null,
            options: { x: 0, y: 0 },
            isDrag: false,
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.currentSection = this.attach ? document.querySelector(this.attach) : this.$parent.$el;

            if (this.currentSection) {
                this.currentSection.addEventListener('mousemove', this.onMoveCursor);
                this.currentSection.addEventListener('mouseleave', this.onToggleCursor);
                this.currentSection.addEventListener('mouseenter', this.onToggleCursor);

                this.currentSection.addEventListener('mousedown', this.onMouseDown);
                this.currentSection.addEventListener('mouseup', this.onMouseUp);
                document.addEventListener('scroll', this.onScroll);
                this.currentSection.style.cursor = 'none';
            }

            if (this.inModal) {
                this.options.x = window.innerWidth / 2;
                this.options.y = window.innerHeight / 2;
                this.$gsap.set(this.$refs.wrapper, this.options);
            }
        });
    },

    beforeDestroy() {
        if (this.currentSection) {
            this.currentSection.removeEventListener('mousemove', this.onMoveCursor);
            this.currentSection.removeEventListener('mouseleave', this.onToggleCursor);
            this.currentSection.removeEventListener('mouseenter', this.onToggleCursor);
            this.currentSection.removeEventListener('mousedown', this.onMouseDown);
            this.currentSection.removeEventListener('mouseup', this.onMouseUp);
            document.removeEventListener('scroll', this.onScroll);
            this.currentSection.style.cursor = '';
        }

        this.hideCursor = true;
    },

    methods: {
        onMoveCursor(e) {
            this.hideCursor = this.alwaysVisible ? false : e?.target?.closest(`.${this.classExclusion}`);
            let x =e.clientX;
            let y =e.clientY;

            if (this.absolute) {
                const size = this.currentSection.getBoundingClientRect();
                x-= size.left;
                y-= size.top;
            }
            this.options = {
                x,
                y,
            };

            this.$gsap.set(this.$refs.wrapper, this.options);
        },

        onToggleCursor(e) {
            this.hideCursor = e.type === 'mouseleave';

            if (!this.hideCursor) {
                this.currentSection.style.cursor = 'none';
            } else {
                this.currentSection.style.cursor = '';
            }
        },

        onScroll() {
            clearInterval(this.interval);
            this.interval = setTimeout(() => {
                this.handleCheckPosition();
            }, 80);
        },

        onMouseDown() {
            this.isDrag = true;
        },

        onMouseUp() {
            this.isDrag = false;
        },

        handleCheckPosition() {
            const currentScroll = document.documentElement.scrollTop + this.options.y;
            const top = this.currentSection.getBoundingClientRect().top + window.pageYOffset;
            const height = this.currentSection.getBoundingClientRect().height;
            const endPos = top + height;

            if (currentScroll < top || currentScroll > endPos) {
                this.currentSection.style.cursor = '';
                this.onToggleCursor({ type: 'mouseleave' });
            } else {
                this.currentSection.style.cursor = 'none';
            }
        },
    },
};
