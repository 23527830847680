
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';

export default {
    name: 'PurposeCard',

    components: {
        VImageSrcSet,
    },

    props: {
        card: {
            type: Object,
            default: () => ({}),
        },
    },
};
