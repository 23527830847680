// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StaggeredTitle_HZRN0{overflow:hidden;position:relative}.StaggeredTitle_HZRN0._xl_lQ3hJ .wrapper_M4RHu div{font-family:\"Bebas-Neue\",sans-serif;font-size:6.8rem;font-weight:700;letter-spacing:-.136rem;line-height:1;padding:3.6rem}.StaggeredTitle_HZRN0._xl_lQ3hJ .wrapper_M4RHu div span+span{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1}.StaggeredTitle_HZRN0._large_IWJmP .wrapper_M4RHu div{font-family:\"Bebas-Neue\",sans-serif;font-size:5.8rem;font-weight:700;letter-spacing:-.116rem;line-height:1;padding:3.6rem}.StaggeredTitle_HZRN0._large_IWJmP .wrapper_M4RHu div span+span{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1}.StaggeredTitle_HZRN0._medium_afOB- div{font-size:4.4rem;padding:1.6rem}.StaggeredTitle_HZRN0._medium_afOB- div span+span,.StaggeredTitle_HZRN0._small_R\\+oKH div{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1}.StaggeredTitle_HZRN0._small_R\\+oKH div{padding:1.2rem}.StaggeredTitle_HZRN0._small_R\\+oKH div span+span{font-size:1.8rem}.wrapper_M4RHu{display:flex;position:relative}.wrapper_M4RHu div{align-items:flex-start;box-shadow:0 -20px 60px 0 #00000040;display:flex;font-size:5.8rem;left:0;letter-spacing:-.116rem;padding:3.6rem;position:absolute;top:calc(100% - var(--padding));width:100%}.wrapper_M4RHu div,.wrapper_M4RHu div span+span{font-family:\"Bebas-Neue\",sans-serif;font-weight:700;line-height:1}.wrapper_M4RHu div span+span{font-size:2.8rem;letter-spacing:-.056rem;margin-left:1.2rem;opacity:.5}.wrapper_M4RHu>div{box-shadow:none;top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"StaggeredTitle": "StaggeredTitle_HZRN0",
	"_xl": "_xl_lQ3hJ",
	"wrapper": "wrapper_M4RHu",
	"_large": "_large_IWJmP",
	"_medium": "_medium_afOB-",
	"_small": "_small_R+oKH"
};
module.exports = ___CSS_LOADER_EXPORT___;
