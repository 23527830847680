// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OpportunitiesCard_D6Kt8{border:1px solid #ccb8ff;box-shadow:inset 0 0 80px 0 #8d5fff;display:flex;flex-direction:column;overflow:hidden;padding:2.4rem;position:relative;width:44.8rem}@media(max-width:1279.98px){.OpportunitiesCard_D6Kt8{padding:2rem;width:100%}}.top_cTYnX{align-items:center;display:flex;justify-content:space-between;margin-bottom:4rem}.title_mw-VF{background-color:#fff;border-radius:.6rem;color:#09040d;font-size:1rem;font-weight:500;line-height:1.16rem;padding:.8rem;text-transform:uppercase}.number_z6TCH,.title_mw-VF{line-height:1;-webkit-user-select:none;user-select:none}.number_z6TCH{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem}@media(max-width:1279.98px){.number_z6TCH{font-size:1.8rem}}.text_928Yi{font-family:\"Bebas-Neue\",sans-serif;font-size:4.2rem;font-size:3.6rem;font-weight:700;letter-spacing:-.084rem;line-height:1;margin-bottom:auto;-webkit-user-select:none;user-select:none}@media(max-width:1279.98px){.text_928Yi{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1}}.description_Td243{color:#b6b4b7;font-size:1.5rem;font-weight:400;line-height:2.1rem;margin-top:1.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"OpportunitiesCard": "OpportunitiesCard_D6Kt8",
	"top": "top_cTYnX",
	"title": "title_mw-VF",
	"number": "number_z6TCH",
	"text": "text_928Yi",
	"description": "description_Td243"
};
module.exports = ___CSS_LOADER_EXPORT___;
