// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventFilters_ohSfc{align-items:center;display:flex;justify-content:space-between;width:100%}@media(max-width:767.98px){.EventFilters_ohSfc{align-items:unset;display:block;justify-content:unset}}.EventFilters_ohSfc._fixed_yo2sy{box-shadow:0 4px 20px 0 #00000040}.EventFilters_ohSfc._fixed_yo2sy .tabs_Gq\\+sU{border-bottom:1px solid #3a363d;margin-bottom:0;padding:1.6rem}.EventFilters_ohSfc._fixed_yo2sy .wrapper_3GCuN{margin-left:1.6rem}@media(max-width:767.98px){.tabs_Gq\\+sU{border-bottom:1px solid #3a363d;margin:0 -1.6rem 1.6rem;padding:1.6rem;width:calc(100% + 3.2rem)}}.wrapper_3GCuN{align-items:center;display:flex;justify-content:flex-end;margin-left:2.4rem}@media(max-width:767.98px){.wrapper_3GCuN{justify-content:unset;margin:1.6rem 0;overflow-x:auto}}.tag_KBcp0:not(:last-of-type){margin-right:.6rem}@media(max-width:767.98px){.tag_KBcp0:last-child{margin-right:1.6rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"EventFilters": "EventFilters_ohSfc",
	"_fixed": "_fixed_yo2sy",
	"tabs": "tabs_Gq+sU",
	"wrapper": "wrapper_3GCuN",
	"tag": "tag_KBcp0"
};
module.exports = ___CSS_LOADER_EXPORT___;
