// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PolicyDisclaimer_Zfv2o{color:#848286;font-size:12px;font-weight:400;line-height:14px}.PolicyDisclaimer_Zfv2o a{color:#b6b4b7;position:relative;-webkit-text-decoration:none;text-decoration:none}.PolicyDisclaimer_Zfv2o a span{border-bottom:1px solid #0000;transition:opacity .3s ease,border-bottom-color .3s ease}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.PolicyDisclaimer_Zfv2o a:hover span{border-bottom-color:#fff;color:#fff}}.PolicyDisclaimer_Zfv2o a:active{opacity:.7}@media(max-width:1279.98px){.PolicyDisclaimer_Zfv2o a span{border-bottom-color:#fff;color:#fff}}@media(max-width:767.98px){.PolicyDisclaimer_Zfv2o br{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"PolicyDisclaimer": "PolicyDisclaimer_Zfv2o"
};
module.exports = ___CSS_LOADER_EXPORT___;
