
// components
import VTabBar from '~/components/ui/tabs/VTabBar.vue';
import VTag from '~/components/ui/tag/VTag.vue';

export default {
    name: 'EventFilters',

    components: {
        VTabBar,
        VTag,
    },

    props: {
        specs: {
            type: Object,
            default: () => ({}),
        },

        values: {
            type: Object,
            default: () => ({}),
        },

        tags: {
            type: Array,
            default: () => [],
        },

        showTabs: {
            type: Boolean,
            default: false,
        },

        fixed: {
            type: Boolean,
            default: false,
        },

        tabs: {
            type: Array,
            default: () => [],
        },
    },
};
